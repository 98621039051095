import React from 'react'
import { graphql } from 'gatsby'
import ContentBlock from "../components/content-block"

import Layout from '../components/layout'

const GettingStarted = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <ContentBlock wrapper>
      <div className="blog-post-container">
        <div className="blog-post">
          <h1>{frontmatter.title}</h1>
          <h2>{frontmatter.date}</h2>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      </ContentBlock>
    </Layout>
  );
};

export const pageQuery = graphql`
  query GettingStartedContent {
    markdownRemark(fileAbsolutePath: {regex : "/getting-started/"}) {
      id
      html
      rawMarkdownBody
      frontmatter {
        title
      }
    }
  }
`;

export default GettingStarted;
